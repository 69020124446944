<template>
<div class="factory-index">
    <a-form layout="inline" :labelCol="{span: 4}" :wrapperCol="{span: 20}">
        <a-row :gutter="20">
            <a-col :span="8">
                <a-form-item label="厂商名称" >
                    <a-input placeholder="输入厂商名称" v-model="params.factoryName" allowClear @pressEnter="onSearch" @change="onSearch"/>
                </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item >
                <a-button type="primary" @click="onSearch">  查询 </a-button>
                <a-button type="primary" style="margin-left: 10px;" @click="addFactory">  新增厂商 </a-button>
                <a-popconfirm
                    title="确定删除?"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="confirmDelete('multiple')"
                    @cancel="cancelDelete"
                >
                    <a-button type="danger" style="margin-left: 10px;" @click="deleteFactory">  批量删除 </a-button>
                </a-popconfirm>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <a-table  size="small" bordered  :columns="columns" :data-source="tableData" style="margin-top: 20px;" :loading="loading" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"   :pagination="pagination" >
            <span slot="action" slot-scope="text, record">
                <a-popconfirm
                    title="确定删除?"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="confirmDelete('single', record)"
                    @cancel="cancelDelete"
                >
                    <a>删除</a>
                </a-popconfirm>
                <a style="margin-left: 13px;" @click="edit(record)">编辑</a>
            </span>
        </a-table>
    <add-factory-drawer ref="addFactoryDrawer" @query="queryFactoryList"/>
</div>

</template>

<script>
import addFactoryDrawer from '@/components/factoryMgt/component/addFactoryDrawer'
import { queryFactoryListApi, deleteFactoryApi } from '@/api/factory'
import moment from 'moment'
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
const columns = [
       {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            width: 0
        },
        {
            title: '厂商名称',
            dataIndex: 'factoryName',
            key: 'factoryName',
            ellipsis: true,
            align: 'center'
        },
        {
            title: '厂商编号',
            dataIndex: 'factoryCode',
            key: 'factoryCode',
            ellipsis: true,
            width: 0
        },
        {
            title: '板块',
            dataIndex: 'plate',
            key: 'plate',
            ellipsis: true,
            align: 'center'
        },
        {
            title: '设备前缀',
            dataIndex: 'devicePrefix',
            key: 'devicePrefix',
            ellipsis: true,
            align: 'center'
        },
        {
            title: '芯片前缀',
            dataIndex: 'chipPrefix',
            key: 'chipPrefix',
            ellipsis: true,
            align: 'center'
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 120,
            scopedSlots: { customRender: 'action' },
        }
]
export default {
    components: {addFactoryDrawer},
    data() {
        return {
            params: {},
            loading: false,
            rowSelection,
            selectedRowKeys: [],
            columns,
            tableData: [],
            pagination: {
                pageSize: 10,
                current: 1,
                total: 0,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total) => {
                return '共' + total + '条'
                },
                pageSizeOptions: ['10', '20', '30', '40', '50', '60', '70'],
                onChange: (current, size) => this.changePage(current, size),
                onShowSizeChange:(current, pageSize)=>this.changePage(current, pageSize),
            },
        }
    },
    mounted() {
        this.queryFactoryList()
    },
    methods: {
        edit(record) {
            this.$refs.addFactoryDrawer.showDrawer(record, '编辑厂商')
        },
        cancelDelete() {
            this.selectedRowKeys = []
        },
        confirmDelete(type, data) {
            let ids = []
            if(type == 'multiple') {
                this.selectedRowKeys.forEach(item => {
                    ids.push(this.tableData[item].id)
                })
            } else {
                ids.push(data.id)
            }
            this.deleteByIds(ids)
        },
        deleteByIds(ids) {
            console.log('要删除的项' + ids)
            deleteFactoryApi(ids).then(res => {
                this.$message.success('删除成功')
                this.pagination.current = 1
                this.pagination.total = 0
                this.queryFactoryList()
                this.selectedRowKeys = []
            })
            .catch(() => {
                this.$message.error('删除失败')
            })
        },
        queryFactoryList() {
            this.loading = true
            this.params.page = this.pagination.current
            this.params.limit = this.pagination.pageSize
            queryFactoryListApi({...this.params}).then(res => {
                this.tableData = res.result.content
                this.pagination.current = res.result.pageNo + 1
                this.pagination.total = res.result.totalElements
                this.params.page = this.pagination.current
                this.loading = false
            })
        },
        onSearch() {
            this.queryFactoryList()
        },
        addFactory() {
            this.$refs.addFactoryDrawer.showDrawer({}, '新增厂商')
        },
        deleteFactory() {},
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        changePage(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.queryFactoryList()
        }
    }
}
</script>
<style lang="scss">
.factory-index{
  .ant-form-item{
      width: 100%;
    }
}
</style>